<template>
	<div
		style="
			height: calc(100vh - 276px);
			overflow-y: auto;
			background-color: #f5f5f5 !important;
			overflow-x: hidden;
		"
	>
		<v-layout class="mt-3">
			<v-flex class="d-flex align-top justify-content-between ma-3">
				<div v-if="pageLoading" class="text-center">
					<v-progress-circular indeterminate color="#0d47a1"></v-progress-circular>
				</div>
				<div v-else class="text-h4 fw-600 blue--text dark">{{ Business.name }}</div>
				<div>
					<div v-if="pageLoading" class="text-center">
						<v-progress-circular indeterminate color="#0d47a1"></v-progress-circular>
					</div>
					<template v-else>
						<div class="text-h5 fw-600 text-muted">{{ Business.name }}</div>
						<div class="fw-600 text-muted">SEO Monthly Report : {{ Business.report_period }}</div>
					</template>
				</div>
				<div class="fw-500">
					<DateRangePicker
						:inlineStyle="{ minWidth: '280px !important' }"
						variant="solo"
						v-model="DateFilter"
						type="month"
						@change="filterAnalytics"
						@click:clear="resetAnalyticsFilter"
					></DateRangePicker>
				</div>
			</v-flex>
		</v-layout>

		<template>
			<v-layout class="blue mt-6">
				<v-flex class="d-flex align-center justify-content-between ma-3">
					<div class="text-h4 white--text fw-600 ml-6">Overall Traffic Overview</div>
					<div>
						<div class="fw-500 mr-6 white--text">Data from Google Analytics</div>
					</div>
				</v-flex>
			</v-layout>
			<template v-if="pageLoading">
				<div class="mx-20 my-40 text-center">
					<v-progress-linear color="orange darken-2" indeterminate :height="15"></v-progress-linear>
				</div>
			</template>
			<template v-else-if="NullDataRecieved">
				<h2 class="text-center mt-36 text-muted">No report data</h2>
			</template>
			<template v-else>
				<div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr" class="px-3 mt-3">
					<div class="white elevation-4 mr-3 rounded-lg px-2 py-2">
						<div class="fw-500">Views</div>
						<div class="text-h4">{{ Views.views }}</div>
						<span
							class="fw-500"
							:class="{
								'green--text': Views.views_diff > 0,
								'red--text': Views.views_diff < 0,
							}"
						>
							<v-icon :color="Views.views_diff > 0 ? 'green' : 'red'" x-small>{{
								Views.views_diff > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
							}}</v-icon>
							{{ Views.views_diff }}%
						</span>
					</div>
					<div class="white elevation-4 rounded-lg px-2 py-2 mx-3">
						<div class="fw-500">Total users</div>
						<div class="text-h4">{{ Users.users }}</div>
						<span
							class="fw-500"
							:class="{
								'green--text': Users.users_diff > 0,
								'red--text': Users.users_diff < 0,
							}"
						>
							<v-icon :color="Users.users_diff > 0 ? 'green' : 'red'" x-small>{{
								Users.users_diff > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
							}}</v-icon>
							{{ Users.users_diff }}%
						</span>
					</div>
					<!-- 			<div class="elevation-4 rounded-lg px-2 py-2 mx-3">
					<div class="fw-500 text-h6">Sessions</div>
					<div class="d-flex mt-2 justify-space-between align-center">
						<div class="text-h3">{{ Sessions.sessions }}</div>
						<div
							class="fw-500 mr-9"
							style="font-size: 17px !important"
							:class="{
								'green--text': Sessions.sessions_diff > 0,
								'red--text': Sessions.sessions_diff < 0,
							}"
						>
							<v-icon :color="Sessions.sessions_diff > 0 ? 'green' : 'red'" x-small>{{
								Sessions.sessions_diff > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
							}}</v-icon>
							{{ Sessions.sessions_diff }}%
						</div>
					</div>
				</div> -->
					<div class="white elevation-4 rounded-lg px-2 py-2 mx-3">
						<div class="fw-500">Sessions</div>
						<div class="text-h4">{{ Sessions.sessions }}</div>
						<div
							class="fw-500 mr-9"
							:class="{
								'green--text': Sessions.sessions_diff > 0,
								'red--text': Sessions.sessions_diff < 0,
							}"
						>
							<v-icon :color="Sessions.sessions_diff > 0 ? 'green' : 'red'" x-small>{{
								Sessions.sessions_diff > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
							}}</v-icon>
							{{ Sessions.sessions_diff }}%
						</div>
					</div>
					<div class="white elevation-4 rounded-lg px-2 py-2 ml-3">
						<div class="fw-500">Engagement rate</div>
						<div class="text-h4">{{ Engagement_Rate.engagement_rate }}</div>
						<span
							class="fw-500 mr-9"
							:class="{
								'green--text': Engagement_Rate.engagement_rate_diff > 0,
								'red--text': Engagement_Rate.engagement_rate_diff < 0,
							}"
						>
							<v-icon :color="Engagement_Rate.engagement_rate_diff > 0 ? 'green' : 'red'" x-small>{{
								Engagement_Rate.engagement_rate_diff > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
							}}</v-icon>
							{{ Engagement_Rate.engagement_rate_diff }}%
						</span>
					</div>
				</div>

				<v-layout class="mt-6">
					<v-row>
						<v-col md="7" class="text-start">
							<div class="blue--text ml-2 h5 fw-600">Which Traffic Channel are Driving Traffic?</div>
							<div class="white h-100 elevation-4 ml-2 rounded-lg">
								<apexchart
									type="bar"
									:options="Session_conversion_rate_options"
									:series="Session_conversion_rate_series"
									:height="360"
								></apexchart>
							</div>
						</v-col>
						<v-col md="5" class="text-end pr-9">
							<div class="blue--text ml-8 h5 fw-600">Which devices are driving engagement?</div>
							<div class="white d-flex h-100 justify-space-around align-center elevation-4 rounded-lg">
								<div>
									<apexchart
										type="donut"
										:options="Engagement_sessions_options"
										:series="Engagement_sessions"
										width="360"
									></apexchart>
								</div>
								<div class="flex-grow-1" style="max-width: 200px">
									<div
										class="border-bottom-dotted py-1 px-1"
										v-for="(row, index) in engagement_session_list"
										:key="index"
									>
										<div class="fw-500 text-capitalize fs-12 d-flex align-center text-right">
											<v-spacer></v-spacer>
											<span :class="`${row.color}--texttt text-truncate`">
												{{ row.name }}
											</span>
											<div style="min-width: 50px">
												<v-chip
													style="min-width: 28px; min-height: 28px"
													medium
													class="pa-1 d-inline-flex justify-center"
													:color="row.color"
												>
													<span class="fs-13 white--text">
														{{ row.total }}
													</span>
												</v-chip>
											</div>
										</div>
									</div>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-layout>

				<v-layout class="mt-6">
					<div class="blue--text ml-2 mt-3 h5 fw-600">How is your website performing?</div>
				</v-layout>

				<v-layout class="d-flex justify-center">
					<div class="ml-2 mb-3 analytics-data-table">
						<v-data-table
							:headers="Website_Performing"
							:items="Website_Performing_list"
							:items-per-page="15"
							class="elevation-5 mr-3"
							fixed-header
							dense
							height="250"
							style="padding: 0 !important"
						>
							<template #no-data>
								<p class="text-center my-auto">No data found</p>
							</template>

							<template #foot>
								<tfoot v-if="Website_Performing_list.length > 1" style="position: sticky; bottom: 0">
									<tr style="background-color: #fff !important">
										<td class="fw-600 fs-18">Grand Total</td>
										<td class="fw-600 fs-18">
											{{ Total_Website_Performing ? Total_Website_Performing.total_users : "-" }}
										</td>
										<td class="fw-600 fs-18">
											{{ Total_Website_Performing ? Total_Website_Performing.total_sessions : "-" }}
										</td>
										<td class="fw-600 fs-18">
											{{ Total_Website_Performing ? Total_Website_Performing.total_engaged_sessions : "-" }}
										</td>
										<td class="fw-600 fs-18">
											{{ Total_Website_Performing ? Total_Website_Performing.total_events_per_session : "-" }}
										</td>
										<td class="fw-600 fs-18">
											{{ Total_Website_Performing ? Total_Website_Performing.total_engagement_rate : "-" }}
										</td>
										<td class="fw-600 fs-18">
											{{ Total_Website_Performing ? Total_Website_Performing.total_conversions : "-" }}
										</td>
										<td class="fw-600 fs-18">
											{{
												Total_Website_Performing ? Total_Website_Performing.total_user_conversion_rate : "-"
											}}
										</td>
									</tr>
								</tfoot>
							</template>

							<template #header.sess_def_chnl_grp="{ header }">
								<v-tooltip top>
									<template #activator="{ on, attrs }">
										<div
											v-on="on"
											v-bind="attrs"
											class="blue fw-500 py-2 px-2 white--text"
											@click="changeIcon(header.value)"
											style="font-size: 12.5px"
										>
											{{ header.text }}
											<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
												website_performing_iconFonts[header.value]
											}}</v-icon>
										</div>
									</template>
									{{ header.text }}
								</v-tooltip>
							</template>
							<template #header.total_users="{ header }">
								<v-tooltip top>
									<template #activator="{ on, attrs }">
										<div
											v-on="on"
											v-bind="attrs"
											class="blue fw-500 py-2 px-2 white--text"
											@click="changeIcon(header.value)"
											style="font-size: 12.5px"
										>
											{{ header.text }}
											<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
												website_performing_iconFonts[header.value]
											}}</v-icon>
										</div>
									</template>
									{{ header.text }}
								</v-tooltip>
							</template>
							<template #header.sessions="{ header }">
								<v-tooltip top>
									<template #activator="{ on, attrs }">
										<div
											v-on="on"
											v-bind="attrs"
											class="blue fw-500 py-2 px-2 white--text"
											@click="changeIcon(header.value)"
											style="font-size: 12.5px"
										>
											{{ header.text }}
											<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
												website_performing_iconFonts[header.value]
											}}</v-icon>
										</div>
									</template>
									{{ header.text }}
								</v-tooltip>
							</template>
							<template #header.engaged_sessions="{ header }">
								<v-tooltip top>
									<template #activator="{ on, attrs }">
										<div
											v-on="on"
											v-bind="attrs"
											class="blue fw-500 py-2 px-2 white--text"
											@click="changeIcon(header.value)"
											style="font-size: 12.5px"
										>
											{{ header.text }}
											<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
												website_performing_iconFonts[header.value]
											}}</v-icon>
										</div>
									</template>
									{{ header.text }}
								</v-tooltip>
							</template>
							<template #header.evts_per_sess="{ header }">
								<v-tooltip top>
									<template #activator="{ on, attrs }">
										<div
											v-on="on"
											v-bind="attrs"
											class="blue fw-500 py-2 px-2 white--text"
											@click="changeIcon(header.value)"
											style="font-size: 12.5px"
										>
											{{ header.text }}
											<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
												website_performing_iconFonts[header.value]
											}}</v-icon>
										</div>
									</template>
									{{ header.text }}
								</v-tooltip>
							</template>
							<template #header.engagement_rate="{ header }">
								<v-tooltip top>
									<template #activator="{ on, attrs }">
										<div
											v-on="on"
											v-bind="attrs"
											class="blue fw-500 py-2 px-2 white--text"
											@click="changeIcon(header.value)"
											style="font-size: 12.5px"
										>
											{{ header.text }}
											<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
												website_performing_iconFonts[header.value]
											}}</v-icon>
										</div>
									</template>
									{{ header.text }}
								</v-tooltip>
							</template>
							<template #header.conversion="{ header }">
								<v-tooltip top>
									<template #activator="{ on, attrs }">
										<div
											v-on="on"
											v-bind="attrs"
											class="blue fw-500 py-2 px-2 white--text"
											@click="changeIcon(header.value)"
											style="font-size: 12.5px"
										>
											{{ header.text }}
											<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
												website_performing_iconFonts[header.value]
											}}</v-icon>
										</div>
									</template>
									{{ header.text }}
								</v-tooltip>
							</template>
							<template #header.usr_conv_rate="{ header }">
								<v-tooltip top>
									<template #activator="{ on, attrs }">
										<div
											v-on="on"
											v-bind="attrs"
											class="blue fw-500 py-2 d-flex px-2 white--text"
											@click="changeIcon(header.value)"
											style="font-size: 12.5px"
										>
											<div class="text-truncate">
												{{ header.text }}
											</div>
											<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
												website_performing_iconFonts[header.value]
											}}</v-icon>
										</div>
									</template>
									{{ header.text }}
								</v-tooltip>
							</template>
						</v-data-table>
					</div>
				</v-layout>

				<v-layout class="mt-6">
					<v-row>
						<v-col lg="5" md="5">
							<div class="blue--text ml-2 h5 fw-600">Where is your traffic coming from?</div>
							<div
								style="min-height: 29.3rem !important"
								class="white d-flex align-center justify-space-around ml-2 elevation-4 rounded-lg"
							>
								<apexchart
									type="pie"
									:options="Traffic_coming_from_options"
									:series="Traffic_coming_from"
									class="px-0"
									:height="300"
								></apexchart>

								<div style="max-width: 140px">
									<div
										class="border-bottom-dotted py-1 px-1"
										v-for="(row, index) in traffic_coming_from_list"
										:key="index"
									>
										<div class="fw-500 fs-7 d-flex align-center text-right">
											<v-spacer></v-spacer>
											<span :class="`${row.color}--texttt text-truncate`">
												{{ row.name }}
											</span>
											<div style="min-width: 30px">
												<v-chip
													style="min-width: 25px; min-height: 25px"
													medium
													class="pa-1 d-inline-flex justify-center"
													:color="row.color"
												>
													<span class="fs-8 white--text">
														{{ row.total }}
													</span>
												</v-chip>
											</div>
										</div>
									</div>
								</div>
							</div>
						</v-col>
						<v-col lg="7" md="7">
							<div class="blue--text ml-2 h5 fw-600">What are the Top Traffic Landing Pages?</div>
							<div class="mr-2 ml-2 h-100">
								<v-data-table
									:headers="Top_Oraganic_Landing_pages"
									:items="Top_Oraganic_Landing_pages_list"
									:items-per-page="15"
									class="elevation-5 rounded-lg"
									fixed-header
									dense
									height="320"
									style="padding: 0 !important"
								>
									<template #no-data>
										<p class="text-center my-auto">No data found</p>
									</template>

									<template #foot>
										<tfoot style="position: sticky; bottom: 0">
											<tr style="background-color: #fff !important">
												<td></td>
												<td class="fw-600 fs-18">Grand Total</td>
												<td class="fw-600 fs-18">
													{{ Total_PAGE_Path ? Total_PAGE_Path.total_sessions : "-" }}
												</td>
												<td class="fw-600 fs-18">
													{{ Total_PAGE_Path ? Total_PAGE_Path.total_screen_page_view_rate : "-" }}
												</td>
												<td class="fw-600 fs-18">
													{{ Total_PAGE_Path ? Total_PAGE_Path.total_engagement_rate : "-" }}
												</td>
												<td class="fw-600 fs-18">
													{{ Total_PAGE_Path ? Total_PAGE_Path.total_conversions : "-" }}
												</td>
											</tr>
										</tfoot>
									</template>

									<template #header.olp_index="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
									<template #header.olp_page_path="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
									<template #header.olp_sessions="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
									<template #header.olp_views="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
									<template #header.olp_engagement_rate="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
									<template #header.olp_conversions="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
								</v-data-table>
							</div>
						</v-col>
					</v-row>
				</v-layout>

				<v-layout class="px-2 mt-6">
					<v-row>
						<v-col md="6">
							<div class="blue--text ml-2 mt-3 h5 fw-600">How are site sessions trending?</div>
							<div class="white elevation-4 rounded-lg">
								<apexchart
									:series="Session_Trending"
									:options="Session_Trending_options"
									height="300"
								></apexchart>
							</div>
						</v-col>
						<v-col md="6">
							<div class="analytics-data-table">
								<div class="blue--text mt-3 h5 fw-600">How are site sessions trending?</div>
								<v-data-table
									:headers="Session_Trending_Headers"
									:items="Session_Trending_Countries"
									:items-per-page="15"
									class="elevation-4 rounded-lg"
									fixed-header
									dense
									hide-default-footer
									height="313"
									style="padding: 0 !important"
								>
									<template #no-data>
										<p class="text-center my-auto">No data found</p>
									</template>

									<template #foot>
										<tfoot style="position: sticky; bottom: 0">
											<tr style="background-color: #fff !important">
												<td class="fw-600 fs-18">Grand Total</td>
												<td class="fw-600 fs-18">{{ Total_CITY ? Total_CITY.total_sessions : "-" }}</td>
												<td class="fw-600 fs-18">
													{{ Total_CITY ? Total_CITY.total_screen_page_view_rate : "-" }}
												</td>
											</tr>
										</tfoot>
									</template>

									<template #header.country="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
									<template #header.sessions="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
									<template #header.views="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
								</v-data-table>
							</div>
						</v-col>
					</v-row>
				</v-layout>

				<v-layout class="px-2">
					<v-row>
						<v-col md="6">
							<div class="ml-2">
								<div class="blue--text mt-3 h5 fw-600">Engagement by Device</div>
								<div class="white d-flex elevation-4 rounded-lg pa-2 justify-space-around">
									<div style="min-height: 328px !important">
										<div class="fw-500" style="font-size: 14px !important">Sessions</div>
										<apexchart
											type="donut"
											:options="Engagement_by_Device_sessions_options"
											:series="Engagement_by_Device_sessions"
										></apexchart>
										<!-- :height="330" -->

										<div class="d-flex justify-center text-center">
											<div
												class="d-flex align-center text-capitalize mr-2"
												v-for="dev_sess in Engagement_by_Device_sessions_list"
												:key="dev_sess.name"
											>
												<div
													style="border-radius: 50%; height: 15px; width: 15px"
													:style="{ backgroundColor: dev_sess.color }"
													class="mr-1"
												></div>
												<div>
													{{ dev_sess.name }}
												</div>
											</div>
										</div>
									</div>
									<div>
										<div class="fw-500" style="font-size: 14px !important">Views</div>
										<div style="min-height: 328px !important">
											<apexchart
												type="donut"
												:options="Engagement_by_Device_views_options"
												:series="Engagement_by_Device_views"
											></apexchart>
											<!-- :height="330" -->
											<div class="d-flex justify-center text-center">
												<div
													class="d-flex align-center text-capitalize mr-2"
													v-for="dev_sess in Engagement_by_Device_views_list"
													:key="dev_sess.name"
												>
													<div
														style="border-radius: 50%; height: 15px; width: 15px"
														:style="{ backgroundColor: dev_sess.color }"
														class="mr-1"
													></div>
													<div>
														{{ dev_sess.name }}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</v-col>
						<v-col md="6">
							<div class="white elevation-4 rounded-lg mt-9">
								<apexchart
									type="bar"
									:options="Engagement_by_Device_bar_options"
									:series="Engagement_by_Device_bar"
									:height="350"
								></apexchart>
							</div>
						</v-col>
					</v-row>
				</v-layout>

				<v-layout class="mt-3">
					<v-row>
						<v-col md="12">
							<div class="blue--text mt-3 ml-2 h4 fw-600">What are the top Organic Landing Pages?</div>
						</v-col>
						<v-col md="12">
							<div class="ml-2 mb-3 analytics-data-table">
								<v-data-table
									:headers="Top_Oraganic_Landing_pages"
									:items="Top_Oraganic_Landing_pages_list"
									class="elevation-5 mr-3"
									fixed-header
									dense
									:items-per-page="25"
									height="250"
									style="padding: 0 !important"
								>
									<template #no-data>
										<p class="text-center my-auto">No data found</p>
									</template>

									<template #foot>
										<tfoot style="position: sticky; bottom: 0">
											<tr style="background-color: #fff !important">
												<td></td>
												<td class="fw-600 fs-18">Grand Total</td>
												<td class="fw-600 fs-18">
													{{ Total_PAGE_Path ? Total_PAGE_Path.total_sessions : "-" }}
												</td>
												<td class="fw-600 fs-18">
													{{ Total_PAGE_Path ? Total_PAGE_Path.total_screen_page_view_rate : "-" }}
												</td>
												<td class="fw-600 fs-18">
													{{ Total_PAGE_Path ? Total_PAGE_Path.total_engagement_rate : "-" }}
												</td>
												<td class="fw-600 fs-18">
													{{ Total_PAGE_Path ? Total_PAGE_Path.total_conversions : "-" }}
												</td>
											</tr>
										</tfoot>
									</template>

									<template #header.olp_index="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
									<template #header.olp_page_path="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
									<template #header.olp_sessions="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
									<template #header.olp_views="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
									<template #header.olp_engagement_rate="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
									<template #header.olp_conversions="{ header }">
										<v-tooltip top>
											<template #activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="attrs"
													class="blue fw-500 py-2 px-2 white--text"
													@click="changeIcon(header.value)"
													style="font-size: 12.5px"
												>
													{{ header.text }}
													<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
														website_performing_iconFonts[header.value]
													}}</v-icon>
												</div>
											</template>
											{{ header.text }}
										</v-tooltip>
									</template>
								</v-data-table>
							</div>
						</v-col>
					</v-row>
				</v-layout>
			</template>
		</template>
	</div>
</template>
<script>
import MainMixin from "@/core/mixins/main.mixin.js";
import DateRangePicker from "@/view/components/DateRangePickerV2";
import MomentJS from "moment-timezone";
import objectPath from "object-path";

export default {
	name: "GoogleAnalytics",
	mixins: [MainMixin],
	components: {
		DateRangePicker,
	},
	props: {
		analyticsData: {
			type: Object,
			default: () => [],
		},
		filters: {
			type: Object,
			default: () => {},
		},
		pageLoading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			DateFilter: [],
			Business: {
				name: null,
				report_period: null,
			},
			graphs: [
				{
					color: "orange",
					name: "Web Enquiry",
					key: "web_enquiry_count",
				},
				{
					color: "cyan",
					name: "Web Chat",
					key: "web_chat_count",
				},
				{
					color: "brown",
					name: "Email Marketer",
					key: "email_marketer_count",
				},
				{
					color: "light-blue",
					name: "Tele Call",
					key: "tele_call_count",
				},
				{
					color: "red",
					name: "Google Adwords",
					key: "google_adwords_count",
				},
				{
					color: "pink",
					name: "FSM/AMS (Brochure Lead Form)",
					key: "fsm_ams_count",
				},
				{
					color: "purple",
					name: "Facebook",
					key: "facebook_count",
				},
				{
					color: "amber",
					name: "Referral",
					key: "referral_count",
				},
				{
					color: "blue-grey",
					name: "Intern",
					key: "intern_count",
				},
				{
					color: "green",
					name: "None",
					key: "none_count",
				},
			],
			engagement_session_list: [],
			website_performing_iconFonts: {
				sess_def_chnl_grp: "mdi-arrow-up-bold",
				total_users: "mdi-arrow-up-bold",
				sessions: "mdi-arrow-up-bold",
				engaged_sessions: "mdi-arrow-up-bold",
				evts_per_sess: "mdi-arrow-up-bold",
				engagement_rate: "mdi-arrow-up-bold",
				conversion: "mdi-arrow-up-bold",
				usr_conv_rate: "mdi-arrow-up-bold",
			},
			Views: {
				views: null,
				views_diff: null,
			},
			Users: {
				users: null,
				users_diff: null,
			},
			Sessions: {
				sessions: null,
				sessions_diff: null,
			},
			Engagement_Rate: {
				engagement_rate: null,
				engagement_rate_diff: null,
			},
			Session_conversion_rate_series: [
				{
					name: "Session conversion rate",
					data: [],
				},
			],
			Engagement_sessions: [],
			Engagement_sessions_options: {
				chart: {
					type: "donut",
					width: 400,
				},
				legend: {
					position: "bottom",
					show: false,
				},
				colors: [],
				labels: [],
			},
			Traffic_coming_from: [],
			Traffic_coming_from_options: {
				chart: {
					type: "pie",
					width: 400,
				},
				legend: {
					position: "bottom",
					show: false,
				},
				colors: [],
				labels: [],
			},
			traffic_coming_from_list: [],
			Session_Trending: [
				{
					name: "Sessions",
					data: [],
				},
				/* {
					name: "Sessions",
					data: [],
				}, */
			],
			Session_Trending_options: {
				chart: {
					type: "line",
					zoom: {
						type: "x",
						enabled: false,
					},
					dropShadow: {
						enabled: true,
						color: "#000",
						top: 18,
						left: 7,
						blur: 10,
						opacity: 0.2,
					},
					toolbar: {
						show: false,
					},
				},
				colors: ["#77B6EA", "#545454"],
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					categories: [],
					title: {
						text: "Sessions",
						style: {
							fontSize: "15px",
							fontFamily: "Helvetica, Arial, sans-serif",
							fontWeight: 800,
							cssClass: "apexcharts-xaxis-title",
						},
					},
				},
				/* yaxis: {
					min: 0,
					max: 100,
					stepSize: 500,
				}, */
				legend: {
					position: "top",
					horizontalAlign: "right",
					floating: true,
					offsetY: -25,
					offsetX: -5,
				},
			},
			Engagement_by_Device_sessions: [],
			Engagement_by_Device_sessions_options: {
				chart: {
					type: "donut",
					width: 400,
				},
				legend: {
					position: "bottom",
					show: false,
				},
				colors: [],
				labels: [],
			},
			Engagement_by_Device_sessions_list: [],
			Engagement_by_Device_views: [],
			Engagement_by_Device_views_options: {
				chart: {
					type: "donut",
					width: 400,
				},
				legend: {
					position: "bottom",
					show: false,
				},
				colors: [],
				labels: [],
			},
			Engagement_by_Device_views_list: [],
			Engagement_by_Device_bar: [
				{
					name: "desktop",
					data: [
						{
							x: "desktop",
							y: 0,
						},
						{
							x: "mobile",
							y: 0,
						},
						{
							x: "tablet",
							y: 0,
						},
					],
				},
				{
					name: "tablet",
					data: [
						{
							x: "desktop",
							y: 0,
						},
						{
							x: "mobile",
							y: 0,
						},
						{
							x: "tablet",
							y: 0,
						},
					],
				},
				{
					name: "mobile",
					data: [
						{
							x: "desktop",
							y: 0,
						},
						{
							x: "mobile",
							y: 0,
						},
						{
							x: "tablet",
							y: 0,
						},
					],
				},
			],
			Engagement_by_Device_bar_options: {
				/* Chart Options */
				chart: {
					type: "bar",
				},
				/* Tooltip */
				tooltip: {
					y: {
						title: {
							formatter: (_, b) => {
								return b.seriesIndex == 0 ? "Sessions" : "Views";
							},
						},
					},
				},
				/* X Axis */
				xaxis: {
					labels: {
						style: {
							colors: [],
							fontSize: "14px",
							fontFamily: "Helvetica, Arial, sans-serif",
							fontWeight: 500,
							cssClass: "apexcharts-xaxis-label",
						},
					},
				},
				/* Values on Bars */
				dataLabels: {},
				/* Y Axis */
				yaxis: {
					labels: {
						style: {
							colors: [],
							fontSize: "13px",
							fontFamily: "Helvetica, Arial, sans-serif",
							fontWeight: 500,
							cssClass: "apexcharts-xaxis-label",
						},
						formatter: function (value) {
							let val = value.toString();
							let _v = value && value != null ? val.split("")[0].toUpperCase() + val.slice(1) : "";
							return _v;
						},
					},
				},
				/* Plotting Options */
				plotOptions: {
					bar: {
						horizontal: true,
					},
				},
			},
			Session_Trending_Headers: [
				{
					text: "Country",
					align: "start",
					sortable: true,
					value: "country",
					width: 300,
					class: ["px-0", "text-truncate"],
					cellClass: ["fw-500", "px-2"],
				},
				{
					text: "Sessions",
					value: "sessions",
					sortable: true,
					width: 150,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500", "px-2"],
				},
				{
					text: "Views",
					value: "views",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500", "px-2"],
				},
			],
			Session_Trending_Countries: [],
			Website_Performing: [
				{
					text: "Session default channel group",
					align: "start",
					sortable: true,
					value: "sess_def_chnl_grp",
					width: 250,
					class: ["px-0", "text-truncate"],
					cellClass: ["fw-500"],
				},
				{
					text: "Total users",
					value: "total_users",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "Sessions",
					value: "sessions",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "Engaged sessions",
					value: "engaged_sessions",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "Events per sessions",
					value: "evts_per_sess",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "Engagement rate",
					value: "engagement_rate",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "Conversion",
					value: "conversion",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "User conversion rate",
					value: "usr_conv_rate",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
			],
			Website_Performing_list: [],
			Top_Oraganic_Landing_pages: [
				{
					text: "",
					align: "start",
					sortable: true,
					value: "olp_index",
					width: 70,
					class: ["px-0", "text-truncate"],
					cellClass: ["fw-500"],
				},
				{
					text: "Page path",
					align: "start",
					sortable: true,
					value: "olp_page_path",
					width: 300,
					class: ["px-0", "text-truncate"],
					cellClass: ["fw-500"],
				},
				{
					text: "Sessions",
					align: "start",
					sortable: true,
					value: "olp_sessions",
					width: 100,
					class: ["px-0", "text-truncate"],
					cellClass: ["fw-500"],
				},
				{
					text: "Views",
					align: "start",
					sortable: true,
					value: "olp_views",
					width: 70,
					class: ["px-0", "text-truncate"],
					cellClass: ["fw-500"],
				},
				{
					text: "Engagement Rate",
					align: "start",
					sortable: true,
					value: "olp_engagement_rate",
					width: 100,
					class: ["px-0", "text-truncate"],
					cellClass: ["fw-500"],
				},
				{
					text: "Conversions",
					align: "start",
					sortable: true,
					value: "olp_conversions",
					width: 120,
					class: ["px-0", "text-truncate"],
					cellClass: ["fw-500"],
				},
			],
			Top_Oraganic_Landing_pages_list: [],
			Total_CITY: null,
			Total_PAGE_Path: null,
			Total_Website_Performing: null,
			NullDataRecieved: false,
		};
	},
	mounted() {
		this.Business.report_period = MomentJS(new Date()).format("MMMM YYYY");
	},
	computed: {
		Session_conversion_rate_options: {
			get() {
				return {
					/* Chart Options */
					chart: {
						type: "bar",
					},
					/* Tooltip */
					tooltip: {
						y: {
							formatter: function (value) {
								return value.toFixed(2) + "%";
							},
						},
					},
					/* X Axis */
					xaxis: {
						labels: {
							formatter: function (value) {
								return value.toFixed(2) + "%";
							},
							style: {
								colors: [],
								fontSize: "14px",
								fontFamily: "Helvetica, Arial, sans-serif",
								fontWeight: 500,
								cssClass: "apexcharts-xaxis-label",
							},
						},
					},
					/* Values on Bars */
					dataLabels: {
						formatter: function (value) {
							return value + "%";
						},
					},
					/* Y Axis */
					yaxis: {
						labels: {
							style: {
								colors: [],
								fontSize: "13px",
								fontFamily: "Helvetica, Arial, sans-serif",
								fontWeight: 500,
								cssClass: "apexcharts-xaxis-label",
							},
						},
					},
					/* Plotting Options */
					plotOptions: {
						bar: {
							horizontal: true,
						},
					},
				};
			},
		},
	},
	methods: {
		filterAnalytics(date) {
			const [start_date, end_date] = date.split("-");
			const _start = new Date(start_date);
			const _end = new Date(end_date);
			const date_diff = MomentJS(end_date).diff(start_date);
			if (MomentJS.isDate(_start) && MomentJS.isDate(_end) && date_diff > 0) {
				this.$emit("toggleFilters", { start_date, end_date });
			}
		},
		resetAnalyticsFilter() {
			this.DateFilter = [];
			const [start_date, end_date] = this.DateFilter;
			this.$emit("toggleFilters", { start_date, end_date });
			/* if (Array.isArray(this.DateFilter) && this.DateFilter.length == 0) {
			} */
		},
		totalOfItems(arr, key) {
			let sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue[key], 0);
			if (sum.toString().includes(".")) {
				sum = sum.toFixed(2);
			}
			return sum;
		},
		changeIcon(value) {
			this.website_performing_iconFonts[value] =
				this.website_performing_iconFonts[value] == "mdi-arrow-up-bold"
					? "mdi-arrow-down-bold"
					: "mdi-arrow-up-bold";
		},
		hexColor(color) {
			switch (color) {
				case "red":
					return "#F44336";
				case "orange":
					return "#FF9800";
				case "cyan":
					return "#00BCD4";
				case "brown":
					return "#795548";
				case "light-blue":
					return "#03A9F4";
				case "teal":
					return "#009688";
				case "green":
					return "#4CAF50";
				case "blue-grey":
					return "#607D8B";
				case "indigo":
					return "#3F51B5";
				case "amber":
					return "#FFC107";
				case "purple":
					return "#9C27B0";
				case "pink":
					return "#E91E63";
				case "grey":
					return "#e0e0e0";
			}
		},
		getDateTime(date) {
			return new Date(date).getTime();
		},
		quickSort(arr, low, high) {
			if (low < high) {
				let pi = this.partition(arr, low, high);
				this.quickSort(arr, low, pi - 1);
				this.quickSort(arr, pi + 1, high);
			}
		},
		partition(arr, low, high) {
			let compareWithLast = this.getDateTime(arr[high].report_dim_value);
			let i = low - 1;
			for (let j = low; j <= high - 1; j++) {
				let condition = this.getDateTime(arr[j].report_dim_value) < compareWithLast;

				if (condition) {
					i++;
					[arr[i], arr[j]] = [arr[j], arr[i]];
				}
			}
			[arr[i + 1], arr[high]] = [arr[high], arr[i + 1]];
			return i + 1;
		},
	},
	watch: {
		filters: {
			deep: true,
			immediate: true,
			handler(filter_obj) {
				if (filter_obj && Object.keys(filter_obj).length) {
					const { start_date, end_date } = filter_obj;
					this.DateFilter = [start_date, end_date];
				}
			},
		},
		DateFilter(param) {
			if (Array.isArray(param)) {
				this.Business.report_period = param.join("-");
			} else if (typeof param == "string") {
				this.Business.report_period = param || MomentJS(new Date()).format("MMMM YYYY");
			} else {
				this.Business.report_period = MomentJS(new Date()).format("MMMM YYYY");
			}
		},
		analyticsData: {
			immediate: true,
			deep: true,
			handler(data) {
				/* Analytics data will shown here */
				this.NullDataRecieved = false;
				const ANALYTICS_DATA = data;
				if (ANALYTICS_DATA && Object.keys(ANALYTICS_DATA)) {
					this.Business.name = ANALYTICS_DATA.business_name;
					this.Views.views = ANALYTICS_DATA.views.toFixed(2);
					this.Views.views_diff = ANALYTICS_DATA.diff_views.toFixed(2);
					this.Users.users = (+ANALYTICS_DATA.users).toFixed(2);
					this.Users.users_diff = ANALYTICS_DATA.diff_users.toFixed(2);
					this.Sessions.sessions = ANALYTICS_DATA.sessions.toFixed(2);
					this.Sessions.sessions_diff = ANALYTICS_DATA.diff_sessions.toFixed(2);
					this.Engagement_Rate.engagement_rate = ANALYTICS_DATA.engagement_rate.toFixed(2);
					this.Engagement_Rate.engagement_rate_diff = ANALYTICS_DATA.diff_engagement_rate.toFixed(2);

					const REPORT_DATA = objectPath.get(ANALYTICS_DATA, "report_data");

					const SESSION = objectPath.get(REPORT_DATA, "session_default");
					const SESS_L = SESSION.length;
					let sessions = {
						conversion_rate: [],
						traffic_coming: [],
						traffic_options: {
							labels: [],
							colors: [],
						},
						list: [],
					};
					let website_performing_list = [];

					if (SESSION && SESS_L) {
						/* Calculating */
						SESSION.map((conv, index) => {
							sessions.conversion_rate.push({
								x: conv.report_dim_value,
								y: conv.report_sessionConversionRate,
							});

							sessions.traffic_coming.push(conv.report_sessions);
							sessions.traffic_options.labels.push(conv.report_dim_value);
							sessions.traffic_options.colors.push(this.hexColor(this.graphs[index].color));
							sessions.list.push({
								color: this.hexColor(this.graphs[index].color),
								name: conv.report_dim_value,
								total: conv.report_sessions,
							});

							let website_performing = {
								sess_def_chnl_grp: conv.report_dim_value,
								total_users: conv.report_total_users,
								sessions: conv.report_sessions,
								engaged_sessions: conv.report_engagedSessions,
								evts_per_sess: conv.report_eventsPerSession,
								engagement_rate: conv.report_engagementRate,
								conversion: conv.report_conversions,
								usr_conv_rate: conv.report_userConversionRate,
							};

							website_performing_list.push(website_performing);
						});
					}

					const PLATFORM_DEVICE = objectPath.get(REPORT_DATA, "platform_device");
					const PL_DEV_L = PLATFORM_DEVICE.length;
					let engagement_sessions = {
						series: [],
						options: {
							colors: [],
							labels: [],
						},
						list: [],
					};
					if (PLATFORM_DEVICE && PL_DEV_L) {
						PLATFORM_DEVICE.map((eng_sess, index) => {
							engagement_sessions.series.push(eng_sess.report_sessions);
							engagement_sessions.options.labels.push(eng_sess.report_dim_value);
							engagement_sessions.options.colors.push(this.hexColor(this.graphs[index].color));
							engagement_sessions.list.push({
								color: this.hexColor(this.graphs[index].color),
								name: eng_sess.report_dim_value,
								total: eng_sess.report_sessions,
							});
						});
					}

					const DATE = objectPath.get(REPORT_DATA, "date");
					const D_L = DATE.length;
					let session_trending = {
						xaxislabels: [],
						session_trending_a: [],
						session_trending_b: [],
					};
					if (DATE && D_L) {
						/* Sorting data with quick sort */
						const N = DATE.length;
						this.quickSort(DATE, 0, N - 1);

						DATE.map((date) => {
							let label = date.report_dim_value.split(",")[0];
							session_trending.xaxislabels.push(label);
							session_trending.session_trending_a.push(date.report_sessions);
							/* session_trending.session_trending_b.push(date.report_sessions); */
						});
					}

					const DEVICE_CATEGORY = objectPath.get(REPORT_DATA, "device_category");
					const DEV_L = DEVICE_CATEGORY.length;
					let device_category = {
						views: {
							values: [],
							options: {
								labels: [],
								colors: [],
							},
							list: [],
						},
						sessions: {
							values: [],
							options: {
								labels: [],
								colors: [],
							},
							list: [],
						},
					};
					if (DEVICE_CATEGORY && DEV_L) {
						DEVICE_CATEGORY.map((dev_cat, index) => {
							device_category.sessions.values.push(dev_cat.report_sessions);
							device_category.sessions.options.labels.push(dev_cat.report_dim_value);
							device_category.sessions.options.colors.push(this.hexColor(this.graphs[index].color));

							device_category.sessions.list.push({
								color: this.hexColor(this.graphs[index].color),
								name: dev_cat.report_dim_value,
							});

							device_category.views.values.push(dev_cat.report_screenPageViews);
							device_category.views.options.labels.push(dev_cat.report_dim_value);
							device_category.views.options.colors.push(this.hexColor(this.graphs[index].color));

							device_category.views.list.push({
								color: this.hexColor(this.graphs[index].color),
								name: dev_cat.report_dim_value,
							});

							this.Engagement_by_Device_bar[index].name = dev_cat.report_dim_value;

							/* Sessions */
							this.Engagement_by_Device_bar[0].data[index].x = dev_cat.report_dim_value;
							this.Engagement_by_Device_bar[0].data[index].y = dev_cat.report_sessions;

							/* Views */
							this.Engagement_by_Device_bar[1].data[index].x = dev_cat.report_dim_value;
							this.Engagement_by_Device_bar[1].data[index].y = dev_cat.report_screenPageViews;
						});
					}

					const CITY = objectPath.get(REPORT_DATA, "city");
					const CY_L = CITY.length;
					let countries = [];
					if (CITY && CY_L) {
						CITY.map((city) => {
							let newCountry = {
								country: city.report_dim_value,
								sessions: city.report_sessions,
								views: city.report_screenPageViews,
							};
							countries.push(newCountry);
						});
					}

					const TOTAL_REPORT_DATA = objectPath.get(ANALYTICS_DATA, "total_report_data");
					const TOTAL_CITY = objectPath.get(TOTAL_REPORT_DATA, "total_city.0");
					const TOTAL_PAGE_PATH = objectPath.get(TOTAL_REPORT_DATA, "total_page_path.0");
					const TOTAL_SESSION = objectPath.get(TOTAL_REPORT_DATA, "total_session_default.0");

					if (TOTAL_REPORT_DATA && TOTAL_CITY) {
						this.Total_CITY = TOTAL_CITY;
					}
					if (TOTAL_REPORT_DATA && TOTAL_PAGE_PATH) {
						this.Total_PAGE_Path = TOTAL_PAGE_PATH;
					}

					if (TOTAL_REPORT_DATA && TOTAL_SESSION) {
						this.Total_Website_Performing = TOTAL_SESSION;
					}

					const PAGE_PATH = objectPath.get(REPORT_DATA, "page_path");
					const PP_L = PAGE_PATH.length;
					let page_path_list = [];

					if (PAGE_PATH && PP_L) {
						PAGE_PATH.map((pp, index) => {
							let olp = {
								olp_index: index + 1,
								olp_page_path: pp.report_dim_value,
								olp_sessions: pp.report_sessions,
								olp_views: pp.report_screenPageViews,
								olp_engagement_rate: pp.report_engagementRate,
								olp_conversions: pp.report_conversions,
							};
							page_path_list.push(olp);
						});
					}

					/* Assigning Values */
					this.Session_conversion_rate_series[0].data = /* CONVERSION_RATE */ sessions.conversion_rate;
					this.Engagement_sessions = [...engagement_sessions.series];
					this.Engagement_sessions_options.labels = [...engagement_sessions.options.labels];
					this.Engagement_sessions_options.colors = [...engagement_sessions.options.colors];
					this.engagement_session_list = [...engagement_sessions.list];
					this.Traffic_coming_from = sessions.traffic_coming;
					this.Traffic_coming_from_options.labels = sessions.traffic_options.labels;
					this.Traffic_coming_from_options.colors = sessions.traffic_options.colors;
					this.traffic_coming_from_list = [...sessions.list];
					this.Session_Trending_options.xaxis.categories = [...session_trending.xaxislabels];
					this.Session_Trending[0].data = [...session_trending.session_trending_a];
					/* this.Session_Trending[1].data = [...session_trending.session_trending_b] */
					this.Engagement_by_Device_sessions = [...device_category.sessions.values];
					this.Engagement_by_Device_views = [...device_category.views.values];
					this.Engagement_by_Device_sessions_options.labels = [
						...device_category.sessions.options.labels,
					];
					this.Engagement_by_Device_sessions_options.colors = [
						...device_category.sessions.options.colors,
					];
					this.Engagement_by_Device_views_options.labels = [...device_category.views.options.labels];
					this.Engagement_by_Device_views_options.colors = [...device_category.views.options.colors];
					this.Engagement_by_Device_sessions_list = [...device_category.sessions.list];
					this.Engagement_by_Device_views_list = [...device_category.views.list];
					this.Session_Trending_Countries = [...countries];
					this.Top_Oraganic_Landing_pages_list = [...page_path_list];
					this.Website_Performing_list = [...website_performing_list];
				} else {
					this.NullDataRecieved = true;
				}
				this.$emit("pageLoading", false);
			},
		},
	},
	beforeMount() {},
};
</script>
