<template>
	<div
		style="
			height: calc(100vh - 276px);
			background-color: #f5f5f5;
			overflow-y: auto;
			overflow-x: hidden;
		"
	>
		<v-layout class="mt-3 px-2 d-flex justify-space-between align-center">
			<!-- <div class="h3 text-muted">Google Search Console</div> -->
			<a
				class="gb_8d gb_Ic gb_6d"
				aria-label="Google Search Console"
				href="#"
				title="Google Search Console"
				id="sdgBod"
				><img
					class="gb_Mc"
					src="https://ssl.gstatic.com/search-console/scfe/sc_logo_lockup_v3.svg"
					alt=""
					aria-hidden="true"
					role="presentation"
					style="width: 235px; height: 28px"
					data-atf="true"
					data-iml="7283.5"
			/></a>
			<div class="fw-500">
				<DateRangePicker
					:inlineStyle="{ minWidth: '280px !important' }"
					variant="solo"
					v-model="DateFilter"
					type="month"
					@change="filterGSC"
					@click:clear="resetAnalyticsFilter"
				></DateRangePicker>
				<!-- <DateRangePicker
					:inlineStyle="{ minWidth: '280px !important' }"
					variant="solo"
					v-model="DateFilter"
					@change="filterGSC"
					type="month"
					@click:clear="resetAnalyticsFilter"
				/> -->
			</div>
		</v-layout>
		<template v-if="pageLoading">
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate :height="15"></v-progress-linear>
			</div>
		</template>
		<template v-else-if="NullDataRecieved">
			<h2 class="text-center mt-36 text-muted">No report data</h2>
		</template>
		<template v-else>
			<div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr" class="mb-3 mt-3">
				<div
					class="d-flex white p-3 align-center justify-content-between elevation-4 ml-2 mr-3 rounded-lg"
				>
					<div>
						<h4 class="text-muted">Clicks</h4>
						<h1>{{ Clicks }}</h1>
						<div class="d-flex align-center">
							<v-icon :color="Diff_Clicks > 0 ? 'green' : 'red'" size="17">{{
								Diff_Clicks > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
							}}</v-icon>
							<div :class="({ 'red--text': Diff_Clicks < 0, 'green--text': Diff_Clicks > 0 }, 'fs-16')">
								{{ Diff_Clicks }}%
							</div>
						</div>
					</div>
					<div class="ml-6"><v-icon color="purple" size="45">mdi-cursor-default-click</v-icon></div>
				</div>
				<div class="d-flex white p-3 align-center justify-content-between elevation-4 mx-3 rounded-lg">
					<div>
						<h4 class="text-muted">Impressions</h4>
						<h1>{{ Impressions }}</h1>
						<div class="d-flex align-center">
							<v-icon :color="Diff_Impressions > 0 ? 'green' : 'red'" size="17">{{
								Diff_Impressions > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
							}}</v-icon>
							<div
								:class="
									({ 'red--text': Diff_Impressions < 0, 'green--text': Diff_Impressions > 0 }, 'fs-16')
								"
							>
								{{ Diff_Impressions }}%
							</div>
						</div>
					</div>
					<div class="ml-6"><v-icon color="purple" size="45">mdi-eye</v-icon></div>
				</div>
				<div class="d-flex white p-3 align-center justify-content-between elevation-4 mx-3 rounded-lg">
					<div>
						<h4 class="text-muted">Average Positions</h4>
						<h1>{{ Avg_Position }}</h1>
						<div class="d-flex align-center">
							<v-icon :color="Diff_Avg_Position > 0 ? 'green' : 'red'" size="17">{{
								Diff_Avg_Position > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
							}}</v-icon>
							<div
								:class="
									({ 'red--text': Diff_Avg_Position < 0, 'green--text': Diff_Avg_Position > 0 }, 'fs-16')
								"
							>
								{{ Diff_Avg_Position }}%
							</div>
						</div>
					</div>
					<div class="ml-6"><v-icon color="pink" size="45">mdi-chart-areaspline</v-icon></div>
				</div>
				<div
					class="d-flex white p-3 align-center justify-content-between elevation-4 ml-3 mr-2 rounded-lg"
				>
					<div>
						<h4 class="text-muted">Site CTR</h4>
						<h1>{{ CTR }}%</h1>
						<div class="d-flex align-center">
							<v-icon :color="Diff_CTR > 0 ? 'green' : 'red'" size="17">{{
								Diff_CTR > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
							}}</v-icon>
							<div :class="({ 'red--text': Diff_CTR < 0, 'green--text': Diff_CTR > 0 }, 'fs-16')">
								{{ Diff_CTR }}%
							</div>
						</div>
					</div>
					<div class="ml-6">
						<v-icon color="pink" size="45">mdi-chat-processing-outline</v-icon>
					</div>
				</div>
			</div>

			<v-layout class="mt-3">
				<v-row class="mx-0">
					<v-col md="8" cols="12">
						<div class="elevation-4 white rounded-lg">
							<apexchart :series="Clicks_line_Area" :options="Clicks_Line_Area_options" height="400">
							</apexchart>
						</div>
					</v-col>
					<v-col md="4" class="pr-2">
						<div
							class="d-flex white flex-column justify-center align-center elevation-4 rounded-lg h-100 py-3"
						>
							<apexchart
								:options="Engagement_sessions_options"
								:series="Engagement_sessions"
								width="450"
							></apexchart>
							<div class="d-flex justify-center">
								<div
									v-for="(eng_clks, index) in Engagement_sessions_list"
									class="d-flex align-center justify-center mr-2"
									:key="'eng_clks_' + index"
								>
									<div
										class="mr-1"
										:style="{
											backgroundColor: eng_clks.color,
											height: '15px',
											width: '15px',
											borderRadius: '50%',
										}"
									></div>
									<div>{{ eng_clks.name }}</div>
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-layout>

			<v-layout class="mt-3">
				<div class="ml-2 mb-3 analytics-data-table">
					<!-- :items-per-page="-1"  All items -->
					<v-data-table
						:headers="Landing_Pages_headers"
						:items="Landing_Pages_data"
						:items-per-page="100"
						class="elevation-5 mr-3"
						fixed-header
						dense
						height="250"
						style="padding: 0 !important"
					>
						<template #no-data>
							<p class="text-center my-auto">No data found</p>
						</template>

						<template #item.imp_diff="{ value }">
							<div class="text-end">
								{{ value }}%
								<v-icon v-if="value != 0" size="12" :color="value > 0 ? 'green' : 'red'">{{
									value > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
								}}</v-icon>
							</div>
						</template>

						<template #item.clck_diff="{ value }">
							<div class="text-end">
								{{ value }}%
								<v-icon v-if="value != 0" size="12" :color="value > 0 ? 'green' : 'red'">{{
									value > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
								}}</v-icon>
							</div>
						</template>

						<template #item.url_ctr_diff="{ value }">
							<div class="text-end">
								{{ value }}%
								<v-icon v-if="value != 0" size="12" :color="value > 0 ? 'green' : 'red'">{{
									value > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
								}}</v-icon>
							</div>
						</template>

						<template #header.landing_page="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-500 py-2 px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								{{ header.text }}
							</v-tooltip>
						</template>
						<template #header.query="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-500 py-2 px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								{{ header.text }}
							</v-tooltip>
						</template>
						<template #header.impressions="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-500 py-2 px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								{{ header.text }}
							</v-tooltip>
						</template>
						<template #header.imp_diff="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-700 py-2 px-2 text-end white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								Impressions Difference
							</v-tooltip>
						</template>
						<template #header.url_clicks="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-500 py-2 px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								{{ header.text }}
							</v-tooltip>
						</template>
						<template #header.clck_diff="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-700 py-2 text-end px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								Url Clicks difference
							</v-tooltip>
						</template>
						<template #header.url_ctr="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-500 py-2 px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								{{ header.text }}
							</v-tooltip>
						</template>
						<template #header.url_ctr_diff="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-700 py-2 justify-end d-flex px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										<div class="text-truncate">
											{{ header.text }}
										</div>
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								Url CTR difference
							</v-tooltip>
						</template>
					</v-data-table>
				</div>
			</v-layout>

			<v-layout class="mt-3">
				<div class="ml-2 mb-3 analytics-data-table">
					<v-data-table
						:headers="Query_Pages_headers"
						:items="Query_Pages_data"
						:items-per-page="100"
						class="elevation-5 mr-3"
						fixed-header
						dense
						height="250"
						style="padding: 0 !important"
					>
						<template #no-data>
							<p class="text-center my-auto">No data found</p>
						</template>

						<template #item.click_diff="{ value }">
							<div class="text-end">
								{{ value }}%
								<v-icon v-if="value != 0" size="12" :color="value > 0 ? 'green' : 'red'">{{
									value > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
								}}</v-icon>
							</div>
						</template>

						<template #item.imp_diff="{ value }">
							<div class="text-end">
								{{ value }}%
								<v-icon v-if="value != 0" size="12" :color="value > 0 ? 'green' : 'red'">{{
									value > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
								}}</v-icon>
							</div>
						</template>

						<template #item.site_ctr_diff="{ value }">
							<div class="text-end">
								{{ value }}%
								<v-icon v-if="value != 0" size="12" :color="value > 0 ? 'green' : 'red'">{{
									value > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
								}}</v-icon>
							</div>
						</template>

						<template #item.avg_pos_diff="{ value }">
							<div class="text-end">
								{{ value }}%
								<v-icon v-if="value != 0" size="12" :color="value > 0 ? 'green' : 'red'">{{
									value > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"
								}}</v-icon>
							</div>
						</template>

						<template #header.query="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-500 py-2 px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								{{ header.text }}
							</v-tooltip>
						</template>
						<template #header.clicks="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-500 py-2 px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								{{ header.text }}
							</v-tooltip>
						</template>

						<template #header.click_diff="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue justify-end d-flex fw-500 py-2 px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								Clicks difference
							</v-tooltip>
						</template>
						<template #header.impressions="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-500 py-2 px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								{{ header.text }}
							</v-tooltip>
						</template>
						<template #header.imp_diff="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue justify-end d-flex fw-500 py-2 px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								Impressions difference
							</v-tooltip>
						</template>
						<template #header.site_ctr="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-500 py-2 px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								{{ header.text }}
							</v-tooltip>
						</template>
						<template #header.site_ctr_diff="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue justify-end d-flex fw-500 py-2 px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										{{ header.text }}
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								Site CTR difference
							</v-tooltip>
						</template>
						<template #header.avg_pos="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-500 py-2 d-flex px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										<div class="text-truncate">
											{{ header.text }}
										</div>
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								{{ header.text }}
							</v-tooltip>
						</template>
						<template #header.avg_pos_diff="{ header }">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<div
										v-on="on"
										v-bind="attrs"
										class="blue fw-500 justify-end py-2 d-flex px-2 white--text"
										@click="changeIcon(header.value)"
										style="font-size: 12.5px"
									>
										<div class="text-truncate">
											{{ header.text }}
										</div>
										<v-icon tag="span" size="17" class="ml-3" color="grey darken-3">{{
											website_performing_iconFonts[header.value]
										}}</v-icon>
									</div>
								</template>
								Average position difference
							</v-tooltip>
						</template>
					</v-data-table>
				</div>
			</v-layout>
		</template>
	</div>
</template>
<script>
import MainMixin from "@/core/mixins/main.mixin.js";
import objectPath from "object-path";
import { cloneDeep } from "lodash";
import DateRangePicker from "@/view/components/DateRangePickerV2";
import MomentJS from "moment-timezone";
export default {
	name: "GoogleSearchConsole",
	mixins: [MainMixin],
	components: {
		DateRangePicker,
	},
	props: {
		projectId: {
			type: [String, Number],
			default: 0,
		},
		url: {
			type: String,
			default: "",
		},
		searchConsoleData: {
			type: Object,
			default: () => {},
		},
		filters: {
			type: Object,
			default: () => {},
		},
		pageLoading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			searchUrl: "",
			graphs: [
				{
					color: "orange",
					name: "Web Enquiry",
					key: "web_enquiry_count",
				},
				{
					color: "cyan",
					name: "Web Chat",
					key: "web_chat_count",
				},
				{
					color: "brown",
					name: "Email Marketer",
					key: "email_marketer_count",
				},
				{
					color: "light-blue",
					name: "Tele Call",
					key: "tele_call_count",
				},
				{
					color: "red",
					name: "Google Adwords",
					key: "google_adwords_count",
				},
				{
					color: "pink",
					name: "FSM/AMS (Brochure Lead Form)",
					key: "fsm_ams_count",
				},
				{
					color: "purple",
					name: "Facebook",
					key: "facebook_count",
				},
				{
					color: "amber",
					name: "Referral",
					key: "referral_count",
				},
				{
					color: "blue-grey",
					name: "Intern",
					key: "intern_count",
				},
				{
					color: "green",
					name: "None",
					key: "none_count",
				},
			],
			DateFilter: [],
			website_performing_iconFonts: {
				sess_def_chnl_grp: "mdi-arrow-up-bold",
				total_users: "mdi-arrow-up-bold",
				sessions: "mdi-arrow-up-bold",
				engaged_sessions: "mdi-arrow-up-bold",
				evts_per_sess: "mdi-arrow-up-bold",
				engagement_rate: "mdi-arrow-up-bold",
				conversion: "mdi-arrow-up-bold",
				usr_conv_rate: "mdi-arrow-up-bold",
			},
			dataTab: "tab_1",
			Clicks: null,
			Diff_Clicks: null,
			Impressions: null,
			Diff_Impressions: null,
			Avg_Position: null,
			Diff_Avg_Position: null,
			CTR: null,
			Diff_CTR: null,
			Clicks_line_Area: [
				{
					name: "Clicks",
					data: [],
				},
			],
			Engagement_sessions: [],
			Engagement_sessions_list: [],
			Engagement_sessions_options: {
				chart: {
					type: "donut",
					width: 400,
				},
				legend: {
					position: "bottom",
					show: false,
				},
			},
			Landing_Pages_headers: [
				{
					text: "Landing Page",
					align: "start",
					sortable: true,
					value: "landing_page",
					class: ["px-0", "text-truncate"],
					cellClass: ["fw-500"],
				},
				{
					text: "Query",
					value: "query",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "Impressions",
					value: "impressions",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "%△",
					value: "imp_diff",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "Url Clicks",
					value: "url_clicks",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "%△",
					value: "clck_diff",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "Url CTR",
					value: "url_ctr",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "%△",
					value: "url_ctr_diff",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
			],
			Landing_Pages_data: [],
			Query_Pages_headers: [
				{
					text: "Query",
					align: "start",
					sortable: true,
					value: "query",
					class: ["px-0", "text-truncate"],
					cellClass: ["fw-500"],
				},
				{
					text: "Clicks",
					value: "clicks",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "%△",
					value: "click_diff",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "Impressions",
					value: "impressions",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "%△",
					value: "imp_diff",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "Site CTR",
					value: "site_ctr",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "%△",
					value: "site_ctr_diff",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "Average Position",
					value: "avg_pos",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
				{
					text: "%△",
					value: "avg_pos_diff",
					sortable: true,
					class: [
						"px-0",
						"text-truncate",
					] /* cellClass: "krishnTestClass" assign to every cell of table */,
					cellClass: ["fw-500"],
				},
			],
			Query_Pages_data: [],
			NullDataRecieved: false,
		};
	},
	mounted() {
		this.searchUrl = this.url;
	},
	watch: {
		dataTab(param) {
			const { query, parmas } = this.$route;
			if (param) {
				this.$router.push({ name: "seo-detail", query: { ...query, subTab: param }, parmas });
			}
		},
		filters: {
			deep: true,
			immediate: true,
			handler(filter_obj) {
				if (filter_obj && Object.keys(filter_obj).length) {
					const { start_date, end_date } = filter_obj;
					this.DateFilter = [start_date, end_date];
				}
			},
		},
		searchConsoleData: {
			immediate: true,
			deep: true,
			handler(data) {
				this.NullDataRecieved = false;
				const CONSOLE_DATA = data;
				if (CONSOLE_DATA && Object.keys(CONSOLE_DATA).length) {
					/*
					const REPORT_DATA = objectPath.get(CONSOLE_DATA, "report_data");
					*/

					this.Clicks = CONSOLE_DATA.total_clicks || null;
					this.Diff_Clicks = CONSOLE_DATA.diff_clicks || null;

					this.Impressions = CONSOLE_DATA.total_impressions || null;
					this.Diff_Impressions = CONSOLE_DATA.diff_impressions || null;

					this.Avg_Position = CONSOLE_DATA.total_position || null;
					this.Diff_Avg_Position = CONSOLE_DATA.diff_position || null;

					this.CTR = CONSOLE_DATA.total_ctr || null;
					this.Diff_CTR = CONSOLE_DATA.diff_ctr || null;

					const DEVICE_DATA = objectPath.get(CONSOLE_DATA, "deviceData");

					let eng_clks = {
						clicks: [],
						labels: [],
						colors: [],
						list: [],
					};

					if (DEVICE_DATA) {
						DEVICE_DATA.map((dev_d, index) => {
							eng_clks.clicks.push(dev_d.report_clicks);
							eng_clks.labels.push(dev_d.report_keyword);
							eng_clks.colors.push(this.hexColor(this.graphs[index].color));
							eng_clks.list.push({
								color: this.hexColor(this.graphs[index].color),
								name: dev_d.report_keyword,
							});
						});

						this.Engagement_sessions = cloneDeep(eng_clks.clicks);
						this.Engagement_sessions_options.labels = cloneDeep(eng_clks.labels);
						this.Engagement_sessions_options.colors = cloneDeep(eng_clks.colors);
						this.Engagement_sessions_list = cloneDeep(eng_clks.list);
					}

					const TOTAL_CLICKS = objectPath.get(CONSOLE_DATA, "totalClicks");
					let clicks_data = {
						series: [],
					};

					if (TOTAL_CLICKS) {
						const KEYS = Object.keys(TOTAL_CLICKS);
						KEYS.map((key) => {
							let record = TOTAL_CLICKS[key];
							let entry = { x: [record.start, record.end], y: record.value };
							clicks_data.series.push(entry);
						});
						this.Clicks_line_Area[0].data = [...clicks_data.series];
					}

					let landing_pages = [];

					const PAGE_DATA = objectPath.get(CONSOLE_DATA, "pageData");
					if (PAGE_DATA) {
						PAGE_DATA.map((pd) => {
							let n_lnding_pages = {
								landing_page: pd.report_page_url,
								query: pd.report_keyword,
								impressions: pd.report_impressions,
								imp_diff: pd.report_diff_impressions.toFixed(2),
								url_clicks: pd.report_clicks,
								clck_diff: pd.report_diff_clicks.toFixed(2),
								url_ctr: pd.report_ctr,
								url_ctr_diff: pd.report_diff_ctr.toFixed(2),
							};

							landing_pages.push(n_lnding_pages);
						});

						this.Landing_Pages_data = cloneDeep(landing_pages);
					}

					let query_pages = [];

					const QUERY_DATA = objectPath.get(CONSOLE_DATA, "queryData");
					if (QUERY_DATA) {
						QUERY_DATA.map((qp) => {
							let qpage = {
								query: qp.report_keyword,
								clicks: qp.report_clicks,
								click_diff: qp.report_diff_clicks.toFixed(2),
								impressions: qp.report_impressions,
								imp_diff: qp.report_diff_impressions.toFixed(2),
								site_ctr: qp.report_ctr,
								site_ctr_diff: qp.report_diff_ctr.toFixed(2),
								avg_pos: qp.report_position,
								avg_pos_diff: qp.report_diff_position.toFixed(2),
							};

							query_pages.push(qpage);
						});

						this.Query_Pages_data = cloneDeep(query_pages);
					}
				} else {
					this.NullDataRecieved = true;
				}
				this.$emit("pageLoading", false);
			},
		},
	},
	computed: {
		Clicks_Line_Area_options: {
			get() {
				const _this = this;
				return {
					chart: {
						type: "area",
						zoom: {
							type: "x",
							enabled: true,
							autoScaleYaxis: true,
						},
						toolbar: {
							autoSelected: "zoom",
						},
					},
					dataLabels: {
						enabled: false,
					},
					markers: {
						size: 0,
					},
					title: {
						text: "Clicks",
						align: "left",
						floating: false,
						style: {
							fontSize: "14px",
							color: "#263238",
						},
					},
					fill: {
						type: "gradient",
						gradient: {
							shadeIntensity: 1,
							inverseColors: false,
							opacityFrom: 0.5,
							opacityTo: 0,
							stops: [0, 90, 100],
						},
					},
					yaxis: {
						labels: {
							formatter: function (val) {
								return val;
							},
						},
						title: {
							text: "",
						},
					},
					xaxis: {
						type: "category",
						tickPlacement: "between",
						labels: {
							show: true,
							rotate: -30,
							rotateAlways: true,
							/* hideOverlappingLabels: true, */
							formatter: function (value) {
								const start_date = value[0];
								return _this.MOMENTJS(start_date).format("DD MMM YYYY");
							},
						},
					},
					tooltip: {
						shared: false,
						style: {
							fontSize: "14px",
						},
						/* custom: function({ series, seriesIndex, dataPointIndex, w }) {
							console.log({ series, seriesIndex, dataPointIndex, w })
						}, */
						x: {
							formatter: function (date) {
								const [start, end] = date;
								const start_date = _this.MOMENTJS(start).format("DD MMM YYYY");
								const end_date = _this.MOMENTJS(end).format("DD MMM YYYY");
								return `${start_date} to ${end_date}`;
							},
						},
						y: {
							formatter: function (val) {
								return val;
							},
						},
						z: {
							formatter: function () {
								return null;
							},
							title: "",
						},
					},
				};
			},
		},
	},
	methods: {
		filterGSC(date) {
			const [start_date, end_date] = date.split("-");
			const _start = new Date(start_date);
			const _end = new Date(end_date);
			const date_diff = MomentJS(end_date).diff(start_date);
			if (MomentJS.isDate(_start) && MomentJS.isDate(_end) && date_diff > 0) {
				this.$emit("toggleFilters", { start_date, end_date });
			}
		},
		resetAnalyticsFilter() {
			this.DateFilter = [];
			const [start_date, end_date] = this.DateFilter;
			this.$emit("toggleFilters", { start_date, end_date });
			/* if (Array.isArray(this.DateFilter) && this.DateFilter.length == 0) {
			} */
		},
		totalOfItems(arr, key) {
			let sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue[key], 0);
			if (sum.toString().includes(".")) {
				sum = sum.toFixed(2);
			}
			return sum;
		},
		changeIcon(value) {
			this.website_performing_iconFonts[value] =
				this.website_performing_iconFonts[value] == "mdi-arrow-up-bold"
					? "mdi-arrow-down-bold"
					: "mdi-arrow-up-bold";
		},
		hexColor(color) {
			switch (color) {
				case "red":
					return "#F44336";
				case "orange":
					return "#FF9800";
				case "cyan":
					return "#00BCD4";
				case "brown":
					return "#795548";
				case "light-blue":
					return "#03A9F4";
				case "teal":
					return "#009688";
				case "green":
					return "#4CAF50";
				case "blue-grey":
					return "#607D8B";
				case "indigo":
					return "#3F51B5";
				case "amber":
					return "#FFC107";
				case "purple":
					return "#9C27B0";
				case "pink":
					return "#E91E63";
				case "grey":
					return "#e0e0e0";
			}
		},
	},
	beforeMount() {
		const { query } = this.$route;
		const { subTab } = query;
		this.dataTab = subTab || "tab_1";
	},
};
</script>
